import React from "react";
import { Row, Col, Grid } from "antd";
import RSVPForm from "../organisms/RSVPForm";
import { NamesSVG } from "../../img/Naampjes";
import { ArrowDownOutlined } from "@ant-design/icons";
import ScrollAnimation from "react-animate-on-scroll";
const { useBreakpoint } = Grid;

export default function RSVPTemplate() {
  const screens = useBreakpoint();
  return (
    <React.Fragment>
      <Row>
        <Col
          className=""
          style={{
            minHeight: "95vh",
            backgroundColor: "white",
            width: "100vw",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          span={24}
        >
          <img
            src="/img/flower.png"
            alt="flower"
            className="fadeIn"
            style={{
              maxHeight: "25vh",
              transform: "rotate(180deg)",
              animation: "fadeIn .3s ",
              position: "absolute",
              top: 0,
            }}
          />

          <img
            src="/img/saveTheDate.png"
            alt="flower"
            className="fadeIn"
            style={{
              maxHeight: "30vh",
              animation: "fadeIn .3s ",
              maxWidth: "100vw",
            }}
          />

          <img
            src="/img/flower.png"
            alt="flower"
            style={{
              maxHeight: "25vh",
              margin: "0 auto",
              position: "absolute",
              bottom: 0,
            }}
          />
          <ArrowDownOutlined
            className="arrow-down animated-arrow bounce"
            style={{ position: "absolute", bottom: 20, left: 20 }}
          />
        </Col>
      </Row>
      <ScrollAnimation animateIn="fadeIn">
        <Row gutter="60" className={"content-row"}>
          <Col className="" span={screens.xs ? 24 : 12}>
            <div>
              <h1>You're inivited</h1>
              <p>
                Wat leuk dat jullie een kijkje nemen op onze website! Het is
                eindelijk zover. Op donderdag 18 mei 2023, hopen wij, Jesse en
                Rosemarie, Het jawoord aan elkaar te kunnen geven. Er wordt
                gezegd: drie keer is scheepsrecht, dus daar gaan we voor. Wij
                hebben er zin in! Hieronder vind je verschillende informatie
                over onze bruiloft.
              </p>
            </div>
          </Col>
          <Col span={screens.xs ? 24 : 12}>
            <img src="../../img/invite.jpg" alt="Jesse  & Roos" />
          </Col>
        </Row>
      </ScrollAnimation>
      <div className="seperator">
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(-90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
      </div>
      <ScrollAnimation animateIn="fadeIn" offset={400}>
        <Row
          gutter="60"
          justify="center"
          id="important"
          className="content-row"
        >
          <Col span={screens.xs ? 24 : 12}>
            <div
              className="toast-image"
              style={{ backgroundImage: "url(../../img/toast.jpg)" }}
              alt="Toasting"
            />
          </Col>
          <Col className="" span={screens.xs ? 24 : 12}>
            <h2>Het belangrijkste </h2>
            <ul>
              <li>
                <strong>Datum: </strong>Donderdag 18 mei 2023
              </li>
              <li>
                <strong>Aanvang: </strong>14.00 uur
              </li>
              <li>
                <strong>Ceremoniemeester: </strong>Rick van Vemde
              </li>
              <li>
                <strong>Telefoonnummer ceremoniemeester: </strong>+31 6 23401616
              </li>
              <li>
                <strong>Dresscode: </strong>Chique
              </li>
              <li>
                <strong>Lokatie: </strong>De Polleplaets, Westhem
              </li>
              <li>
                <strong>RSVP: </strong>
                <a href="#rsvp">zie onderaan de website</a>
              </li>
            </ul>
          </Col>
        </Row>
      </ScrollAnimation>

      <div className="seperator">
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(-90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
      </div>

      <ScrollAnimation animateIn="fadeIn" offset={400}>
        <Row gutter="60" justify="center" id="location" className="content-row">
          <Col className="" span={screens.xs ? 24 : 12}>
            <h2>Lokatie</h2>
            <p>
              Deze keer gaan we proberen om te trouwen in het mooie Friesland!
              We hebben een prachtige lokatie uitgezocht, tussen de weilanden en
              boerderijen, genaamd de{" "}
              <a
                href="https://pollepleats.nl/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ appearance: "none", borderBottom: "1px solid #eaeae" }}
              >
                Pollepleats
              </a>
              . De Pollepleats heeft honderden jaren gefungeerd als boerderij en
              is nu prachtig gerenoveerd. Wij hebben voor deze lokatie gekozen,
              omdat het midden in de Friese natuur staat en we iedereen ook de
              mogelijkheid kunnen bieden om ter plekke te kunnen overnachten.
            </p>
            <br />
            <h4 style={{ textAlign: "center", margin: 0 }}>Adres</h4>
            <p style={{ textAlign: "center", margin: 0 }}> De Kat 20 </p>
            <p style={{ textAlign: "center", margin: 0 }}>8616 LB Westhem </p>
            <p style={{ textAlign: "center", margin: 0 }}>0515-579 342 </p>
            <p style={{ textAlign: "center", margin: 0 }}>
              info@pollepleats.nl
            </p>
          </Col>
          <Col span={screens.xs ? 24 : 12}>
            <img
              src="/img/Pollepleats_Bruiloft-Inspiratie.webp"
              alt="Pollepleats"
            />
          </Col>
        </Row>
      </ScrollAnimation>

      <div className="seperator">
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(-90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
      </div>

      <ScrollAnimation animateIn="fadeIn" offset={400}>
        <Row gutter="60" justify="center" id="program" className="content-row">
          <Col span={screens.xs ? 24 : 12}>
            <img
              src="/img/210420154050.Overkapping.resized.1280x0.jpg"
              alt="Toasting"
            />
          </Col>
          <Col className="" span={screens.xs ? 24 : 12}>
            <h2>Onze dag</h2>
            <ul>
              <li>
                <strong>Aanvang:</strong> 14.00 uur
              </li>
              <li>
                <strong>Start ceremonie:</strong> 14.30 uur
              </li>
              <li>
                <strong>Einde ceremonie:</strong> 15.15 uur
              </li>
              <li>
                <strong>Toasten:</strong> 15.45 uur
              </li>
              <li>
                <strong>Diner:</strong> 18.00 uur
              </li>
              <li>
                <strong>Feest:</strong> 20.00 uur
              </li>
              <li>
                <strong>Laatste ronde:</strong> 00.30 uur
              </li>
            </ul>
          </Col>
        </Row>
      </ScrollAnimation>

      <div className="seperator">
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(-90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
      </div>

      <ScrollAnimation animateIn="fadeIn" offset={400}>
        <Row gutter="60" justify="center" id="rsvp" className="content-row">
          <Col className="" span={screens.xs ? 24 : 12}>
            <h2>Overnachten</h2>
            <p>
              Onze lokatie biedt de mogelijkheid tot een overnachten met ontbijt
              voor al onze gasten. Voor deze overnachting van donderdag 18 mei
              op vrijdag 19 mei 2023 zullen wij een vergoeding vragen die kan
              liggen tussen de 45 en 65 euro per persoon. De kamers variëren van
              2 tot 6 personen. <br />
              <br />
              Uiteraard zijn er ook nog overnachtingsmogelijkheden in de
              omgeving van de locatie. Het staat jullie natuurlijk vrij om
              hiervoor te kiezen. Kies je ervoor om bij de Polleplaets te
              overnachten, dan krijg je via ons of de ceremoniemeester te horen
              wat het definitieve bedrag is en in wat voor een kamer je
              overnacht. Uiteraard gaat alles in overleg. Op de dag zelf zal je
              via de ceremoniemeester de kamersleutel krijgen.
              <br />
              <br />
              <p>
                Wil je blijven slapen? Super gezellig! Dit graag aangeven in de
                RSVP onderaan de pagina.
              </p>
            </p>
          </Col>
          <Col span={screens.xs ? 24 : 12}>
            <img src="/img/pollepleats.jpeg" alt="Pollepleats" />
          </Col>
        </Row>
      </ScrollAnimation>

      <div className="seperator">
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(-90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
        <img
          src="/img/flower.png"
          alt="flower"
          className="fadeIn"
          style={{
            maxHeight: "25vh",
            transform: "rotate(90deg)",
            animation: "fadeIn .3s ",
            maxWidth: "50%",
          }}
        />
      </div>

      <ScrollAnimation animateIn="fadeIn" offset={200}>
        <Row justify="center" gutter="60" className="content-row">
          <Col className="" span={24}>
            <h2>RSVP</h2>
            <p style={{ maxWidth: "85%" }}>
              Hieronder kun je aangeven of jij of jullie aanwezig zijn, of
              jullie een overnachting wensen en of er eventuele allergieën zijn
              waar we rekening mee moeten houden.
            </p>
            <br />

            <RSVPForm id="rsvp" />
          </Col>
        </Row>
      </ScrollAnimation>
    </React.Fragment>
  );
}
