import React from "react";
import { Row, Col, Grid } from "antd";
import { NamesSVG } from "../../img/Naampjes";

const { useBreakpoint } = Grid;
export default function FooterTemplate() {
  const screens = useBreakpoint();

  return (
    <React.Fragment>
      <Row
        justify="space-between"
        align="middle"
        style={{
          padding: "75px 0",
          maxWidth: "1100px",
          width: "100%",
          margin: "0 auto",
          display: "flex",
          justifyContent: " center",
          alignItems: "center",
        }}
      >
        <img
          src="/img/saveTheDate.png"
          style={{ maxWidth: "50vw", margin: "0 auto", padding: "25px 0" }}
          alt="Save the date 18 mei 2023"
        ></img>
      </Row>
    </React.Fragment>
  );
}
