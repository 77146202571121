import React from "react";
import { Row, Col, Grid } from "antd";
import Timer from "../organisms/Timer";
import AddToCalendarBlock from "../atoms/AddToCalendar";
import { NamesSVG } from "../../img/Naampjes";
import RoosOneline from "../../img/RoosOneline";
const { useBreakpoint } = Grid;

function SaveTheDate(props) {
  const screens = useBreakpoint();

  return (
    <div class="save-the-date">
      <Row>
        <Col span={screens.xs ? 24 : 12} offset={screens.xs ? 0 : 6}>
          <p className="getting-married">We're getting married</p>
        </Col>
        <Col span={screens.xs ? 24 : 12} offset={screens.xs ? 0 : 6}>
          <NamesSVG className="brand" />
        </Col>
        <Col span={screens.xs ? 24 : 12} offset={screens.xs ? 0 : 6}>
          <h2 className="save-the-date-title">Save the date</h2>
          <h3 className="save-the-date-subtitle">29-12-2021</h3>
        </Col>
        <Col span={screens.xs ? 24 : 16} offset={screens.xs ? 0 : 4}>
          <Timer />
          {/* <AddToCalendarBlock /> */}
        </Col>
        <RoosOneline />
      </Row>
    </div>
  );
}

export default SaveTheDate;
