import React from "react";
import { Layout, Menu } from "antd";
import RSVPTemplate from "../components/templates/RSVPTemplate";
import FooterTemplate from "../components/templates/Footer";
const { Header, Footer, Sider, Content } = Layout;

// const scrollToItem = (item) => {
//   return document.querySelector(item).scrollIntoView({
//     behavior: "smooth",
//   });
// };

function Rsvp(props) {
  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          zIndex: 10,
          background: "transparent",
        }}
      ></Header>
      <Content
        style={{
          minHeight: "100vh",
          width: "100%",
          margin: "0 auto",
          padding: "0 0 100px",
          fontSize: "16px",
        }}
        className="rsvp-content"
      >
        <RSVPTemplate />
      </Content>
      <Footer
        style={{ height: "100%", color: "white", backgroundColor: "#b6bfdd" }}
        justify="center"
      >
        <FooterTemplate />
      </Footer>
    </Layout>
  );
}

Rsvp.propTypes = {};

export default Rsvp;
