import React, { Fragment } from "react";
import { Table } from "antd";
import { useState } from "react";
import { useEffect } from "react";

export default function Participators() {
  let users = [];
  const [data, setData] = useState(null);

  const getData = () =>
    fetch("/.netlify/functions/rsvp", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((data) => {
        data.map((x) => {
          x.available == true ? (x.available = "Ja") : (x.available = "Nee");
          x.slapen == true ? (x.slapen = "Ja") : (x.slapen = "Nee");
          x.bus == true ? (x.bus = "Ja") : (x.bus = "Nee");
        });

        return setData(data);
      });

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Gezelschap",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Naam",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Naam gast 2",
      dataIndex: "name-person2",
      key: "name-person2",
    },
    {
      title: "Komt",
      dataIndex: "available",
      key: "available",
    },
    // {
    //   title: "Voorgerecht",
    //   dataIndex: "starter",
    //   key: "starter",
    // },
    // {
    //   title: "Voorgerecht garing",
    //   dataIndex: "starterGaring",
    //   key: "starterGaring",
    // },
    // {
    //   title: "Hoofdgerecht",
    //   dataIndex: "mainDish",
    //   key: "mainDish",
    // },
    // {
    //   title: "Hoofdgerecht garing",
    //   dataIndex: "mainGaring",
    //   key: "mainGaring",
    // },
    {
      title: "Dieetwensen",
      dataIndex: "dieetwensen",
      key: "dieetwensen",
    },
    // {
    //   title: "Voorgerecht pers. 2",
    //   dataIndex: "starter2",
    //   key: "starter",
    // },
    // {
    //   title: "Voorgerecht pers. 2 garing",
    //   dataIndex: "starterGaring2",
    //   key: "starterGaring2",
    // },
    // {
    //   title: "Hoofdgerecht pers. 2",
    //   dataIndex: "mainDish2",
    //   key: "mainDish",
    // },
    // {
    //   title: "Hoofdgerecht pers. 2 garing",
    //   dataIndex: "mainGaring2",
    //   key: "mainGaring2",
    // },
    {
      title: "Dieetwensen pers. 2",
      dataIndex: "dieetwensen2",
      key: "dieetwensen",
    },
    {
      title: "Blijft slapen",
      dataIndex: "slapen",
      key: "slapen",
    },
    // {
    //   title: "Bus",
    //   dataIndex: "bus",
    //   key: "bus",
    // },
  ];

  return (
    <Fragment>
      <div>
        {data && (
          <Table dataSource={data} pagination={false} columns={columns}></Table>
        )}
      </div>
    </Fragment>
  );
}
