import React from "react";
import AddToCalendar from "react-add-to-calendar";

export default class AddToCalendarBlock extends React.Component {
  static displayName = "Trouwerij Rosemarie & Jesse";
  state = {
    event: {
      title: "Trouwerij Rosemarie & Jesse",
      description: "",
      location: "Nog niet bekend",
      startTime: "2021-12-29T00:00:00-02:00",
      endTime: "2021-12-29T23:45:00-02:00",
    },
  };

  render() {
    return <AddToCalendar event={this.state.event} displayItemIcons={true} />;
  }
}
