import React, { Fragment } from "react";
import { Form, Select, Input, Switch, Button } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { useState } from "react";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export default function RSVPForm() {
  const [company, setCompany] = useState(null);
  const [available, setAvailable] = useState(null);
  const [starter, setStarter] = useState(null);
  const [main, setMain] = useState(null);
  const [starter2, setStarter2] = useState(null);
  const [main2, setMain2] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [done, setDone] = useState(false);
  const [maingGaring, setMainGaring] = useState(null);
  const [mainGaring2, setMainGaring2] = useState(null);
  const [starterGaring, setStarterGaring] = useState(null);
  const [starterGaring2, setStarterGaring2] = useState(null);

  const onFinish = (data) => {
    console.log(data);
    fetch("/.netlify/functions/rsvp", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setDone(true);
  };

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        "input-number": 3,
        "checkbox-group": ["A", "B"],
        rate: 3.5,
      }}
    >
      <Form.Item
        name="company"
        label="Selecteer uitgenodigd gezelschap"
        hasFeedback
        rules={[{ required: true, message: "Vul het gezelschap in" }]}
      >
        <Select
          placeholder="Kies gezelschap"
          onSelect={(option) => setCompany(option)}
        >
          <Option value="alleen">Alleen</Option>
          <Option value="stel">Als stel</Option>
        </Select>
      </Form.Item>

      <Form.Item name="name" label="Voor- en achternaam gast 1">
        <Input />
      </Form.Item>

      {company === "stel" && (
        <Form.Item name="name-person2" label="Voor- en achternaam gast 2">
          <Input />
        </Form.Item>
      )}

      <Form.Item
        name="available"
        label="Ben je aanwezig?"
        valuePropName="checked"
      >
        <Switch
          onChange={(option) =>
            option ? setAvailable("Ja") : setAvailable("Nee")
          }
        />
      </Form.Item>

      {available === "Ja" && (
        <React.Fragment>
          {/* <Form.Item name="starter" label="Voorgerecht" valuePropName="checked">
            <Select
              placeholder="Selecteer voorgerecht"
              onChange={(value) => setStarter(value)}
            >
              <Option value="Vlees">Vlees</Option>
              <Option value="Vis">Vis</Option>
              <Option value="Soep">Soep</Option>
            </Select>
          </Form.Item> */}

          {/* {starter === "Vlees" && (
            <Form.Item
              name="starterGaring"
              label="Garing"
              valuePropName="checked"
            >
              <Select
                placeholder="Selecteer garing"
                onChange={(value) => setStarterGaring(value)}
              >
                <Option value="rare">Rare</Option>
                <Option value="medium-rare">Medium rare</Option>
              </Select>
            </Form.Item>
          )} */}
          {/* 
          <Form.Item
            name="mainDish"
            label="Hoofdgerecht"
            valuePropName="checked"
          >
            <Select
              placeholder="Selecteer hoofdgerecht"
              onChange={(value) => setMain(value)}
            >
              <Option value="Vlees">Vlees</Option>
              <Option value="Vis">Vis</Option>
              <Option value="Vega">Vega</Option>
            </Select>
          </Form.Item>

          {main === "Vlees" && (
            <Form.Item name="mainGaring" label="Garing" valuePropName="checked">
              <Select
                placeholder="Selecteer garing"
                onChange={(value) => setMainGaring(value)}
              >
                <Option value="rare">Rare</Option>
                <Option value="medium-rare">Medium rare</Option>
              </Select>
            </Form.Item>
          )} */}
          <Form.Item
            name="dieetwensen"
            label="Dieetwensen, Vega of Allergieën:"
          >
            <Input />
          </Form.Item>

          {company == "stel" && (
            <Fragment>
              {/* <Form.Item
                name="starter2"
                label="Voorgerecht pers. 2"
                valuePropName="checked"
              >
                <Select
                  placeholder="Selecteer voorgerecht"
                  onChange={(value) => setStarter2(value)}
                >
                  <Option value="Vlees">Vlees</Option>
                  <Option value="Vis">Vis</Option>
                  <Option value="Soep">Soep</Option>
                </Select>
              </Form.Item> */}

              {/* {starter2 === "Vlees" && (
                <Form.Item
                  name="starterGaring2"
                  label="Garing"
                  valuePropName="checked"
                >
                  <Select
                    placeholder="Selecteer garing"
                    onChange={(value) => setStarterGaring2(value)}
                  >
                    <Option value="rare">Rare</Option>
                    <Option value="medium-rare">Medium rare</Option>
                  </Select>
                </Form.Item>
              )} */}
              {/* 
              <Form.Item
                name="mainDish2"
                label="Hoofdgerecht pers. 2"
                valuePropName="checked"
              >
                <Select
                  placeholder="Selecteer hoofdgerecht"
                  onChange={(value) => setMain2(value)}
                >
                  <Option value="Vlees">Vlees</Option>
                  <Option value="Vis">Vis</Option>
                  <Option value="Vega">Vega</Option>
                </Select>
              </Form.Item>

              {main2 === "Vlees" && (
                <Form.Item
                  name="mainGaring2"
                  label="Garing"
                  valuePropName="checked"
                >
                  <Select
                    placeholder="Selecteer garing"
                    onChange={(value) => setMainGaring2(value)}
                  >
                    <Option value="rare">Rare</Option>
                    <Option value="medium-rare">Medium rare</Option>
                  </Select>
                </Form.Item>
              )} */}

              <Form.Item
                name="dieetwensen2"
                label="Dieetwensen, Vega of Allergieën gast 2:"
              >
                <Input />
              </Form.Item>
            </Fragment>
          )}

          <Form.Item
            name="slapen"
            label="Blijf je slapen?"
            valuePropName="checked"
          >
            <Switch
              onChange={(option) => (option ? setHotel("Ja") : setHotel("Nee"))}
            />
          </Form.Item>
        </React.Fragment>
      )}

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          Verzenden
        </Button>
      </Form.Item>

      {done && (
        <p style={{ textAlign: "center" }}>
          Bedankt voor het invullen, uw RSVP wordt verwerkt
        </p>
      )}
    </Form>
  );
}
